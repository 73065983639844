@charset "UTF-8";
.red {
  color: red;
}

/* --main.html invitation-top reception-top 席札トップのみ使用　　*/
.mainHead {
  margin-bottom: 30px;
}

.mainHead_head {
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media print, screen and (min-width: 751px) {
  .mainHead_head {
    font-size: 24px;
  }
}

.mainHead_head span {
  font-size: 14px;
}

@media print, screen and (min-width: 751px) {
  .mainHead_head span {
    font-size: 22px;
  }
}

.mainHead_body {
  font-size: 14px;
  margin: 0 0 40px 0;
  line-height: 1.5;
}

@media print, screen and (min-width: 751px) {
  .mainHead_body {
    font-size: 22px;
  }
}

.mainHead_body span {
  font-size: 16px;
  color: #f00;
}

@media print, screen and (min-width: 751px) {
  .mainHead_body span {
    font-size: 24px;
  }
}

.mainHead_body strong {
  font-weight: normal;
  background: linear-gradient(transparent 60%, #fafa00 70%, #fafa00 100%);
}

.mainHead_sub {
  width: 100%;
  height: auto;
}

.mainSelect {
  padding: 10px 5px 10px;
  margin-bottom: 20px;
  background: radial-gradient(#efebe8, #f2e4c7);
  background-size: cover;
  border-radius: 10px;
}

@media print, screen and (min-width: 751px) {
  .mainSelect {
    padding: 20px 20px 20px;
    margin-bottom: 30px;
  }
}

.mainSelect_head {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .mainSelect_head {
    font-size: 22px;
    margin-bottom: 30px;
  }
}

.mainSelect_head span {
  font-size: 16px;
}

@media print, screen and (min-width: 751px) {
  .mainSelect_head span {
    font-size: 30px;
  }
}

.mainSelect_body {
  display: flex;
}

.mainSelect_bodyItem {
  width: 49%;
  margin-right: 10px;
}

.mainSelect_bodyItem:last-child {
  margin-right: 0;
}

.mainSelect_bodyHead {
  font-size: 14px;
  margin-bottom: 8px;
}

@media print, screen and (min-width: 751px) {
  .mainSelect_bodyHead {
    font-size: 30px;
  }
}

.mainSelect_bodyBody {
  margin-bottom: 8px;
  width: 100%;
  height: auto;
}

.mainSelect dl dl {
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .mainSelect dl dl {
    margin-bottom: 50px;
  }
}

.mainSelect dl dl dd {
  padding-left: 30px;
  margin-bottom: 8px;
  text-align: left;
}

@media print, screen and (min-width: 751px) {
  .mainSelect dl dl dd {
    padding-left: 50px;
  }
}

.mainSelect_check1 dd {
  font-size: 12px;
  background: url("../img/check1.png") no-repeat;
  background-position: left 10px center;
}

@media print, screen and (min-width: 751px) {
  .mainSelect_check1 dd {
    font-size: 22px;
    background-position: left 20px center;
  }
}

.mainSelect_check2 dd {
  font-size: 12px;
  background: url("../img/check2.png") no-repeat;
  background-position: left 10px center;
}

@media print, screen and (min-width: 751px) {
  .mainSelect_check2 dd {
    font-size: 22px;
    background-position: left 20px center;
  }
}

.mainSelect_bodyFoot {
  font-size: 12px;
  margin-bottom: 14px;
}

@media print, screen and (min-width: 751px) {
  .mainSelect_bodyFoot {
    font-size: 24px;
  }
}

.mainBody_item {
  margin-bottom: 40px;
  padding: 0 10px 0;
}

.mainBody_body {
  padding: 20px 10px 20px;
  border: 2px solid #FFACA4;
  border-radius: 20px;
  overflow: hidden;
}

.mainBody_img {
  margin: 0 0 20px 0;
  width: 100%;
  height: auto;
}

.conbination {
  padding: 90px 0 20px;
  margin-bottom: 20px;
  background: url("../img/combination-bg1.png") repeat-x;
  background-color: #fcfaf6;
  border-radius: 10px;
}

@media print, screen and (min-width: 751px) {
  .conbination {
    margin-bottom: 40px;
  }
}

.conbination_head {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
}

@media print, screen and (min-width: 751px) {
  .conbination_head {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

.conbination_headText {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.conbination_headText-orange {
  background: linear-gradient(transparent 60%, #ffc46e 70%, #ffc46e 100%);
}

.conbination_headText-green {
  background: linear-gradient(transparent 60%, #beea53 70%, #beea53 100%);
}

@media print, screen and (min-width: 751px) {
  .conbination_headText {
    font-size: 26px;
    margin-bottom: 20px;
  }
}

.conbination_body {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.4;
  margin-bottom: 20px;
}

.conbination_body img {
  width: 100%;
  margin-bottom: 10px;
}

.conbination_item {
  flex: 1 1 100%;
  max-width: 33%;
  align-self: flex-end;
  padding: 0 10px;
}

@media print, screen and (min-width: 751px) {
  .conbination_item {
    padding: 0 30px;
  }
}

.conbination h3 {
  font-weight: normal;
}

@media print, screen and (min-width: 751px) {
  .conbination h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

@media print, screen and (min-width: 751px) {
  .conbination p {
    font-size: 20px;
    margin-bottom: 6px;
  }
}

.conbination_down {
  padding: 0 0 0 30px;
  background: url("../img/combination-down.png") no-repeat;
  background-position: left 25% center;
  background-size: 18px;
}

@media print, screen and (min-width: 751px) {
  .conbination_down {
    padding: 0 0 0 60px;
    background-position: left 38% center;
    background-size: 22px;
  }
}

.conbination_line {
  width: 80%;
  margin-bottom: 10px;
}

.conbination .conbination_sum {
  font-size: 18px;
  margin: 0 auto;
  padding: 10px 16px;
  width: 90%;
  background-color: #efefef;
  border-radius: 10px;
}

@media print, screen and (min-width: 751px) {
  .conbination .conbination_sum {
    font-size: 26px;
    max-width: 400px;
  }
}

.subContainer {
  text-align: left;
  padding: 20px 10px 20px;
  border-top: 2px dashed #333333;
}

@media print, screen and (min-width: 751px) {
  .subContainer {
    padding: 30px 60px 30px;
  }
}

.subContainer_head {
  text-align: center;
  font-size: 20px;
  margin: 0 0 15px 0;
}

@media print, screen and (min-width: 751px) {
  .subContainer_head {
    font-size: 40px;
    margin: 0 0 20px 0;
  }
}

.subContainer_body {
  font-size: 12px;
  line-height: 1.8;
  letter-spacing: 4px;
}

@media print, screen and (min-width: 751px) {
  .subContainer_body {
    font-size: 20px;
  }
}

.subContainer_mb {
  margin-bottom: 15px;
}

@media print, screen and (min-width: 751px) {
  .subContainer_mb {
    margin-bottom: 25px;
  }
}
