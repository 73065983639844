@charset "UTF-8";

.mainHead {
  margin-bottom: 30px;
  &_head {
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 40px 0;
    line-height: 1.5;
    @include mq-tb {
      font-size: 24px;
    }
    & span {
      font-size: 14px;
      @include mq-tb {
        font-size: 22px;
      }
    }
  }
  &_body {
    font-size: 14px;
    margin: 0 0 40px 0;
    line-height: 1.5;
    @include mq-tb {
      font-size: 22px;
    }
    & span {
      font-size: 16px;
      color: #f00;
      @include mq-tb {
        font-size: 24px;
      }
    }
    & strong {
      font-weight: normal;
      background: linear-gradient(transparent 60%, #fafa00 70%, #fafa00 100%);
    }
  }
  &_sub {
    width: 100%;
    height: auto;
  }
}
