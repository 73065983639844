@charset "UTF-8";

$color-base: #ffffff;
$color-main-text: #333333;
$color-link: #878378;
$color-visited: #a8a8a8;
$color-white: #fff;
$color-pink: #FFACA4;


// sizes
$base-content-width: 1024px;
$space-unit: 20px;
$sp-space-unit: 15px;
$column-space: $space-unit*2;


//break point
$sp-max-width: 750px;
$tb-min-width: 751px;
$tb-max-width: 1023px;
$pc-min-width: 1024px;

.red {
  color: red;
}
