@charset "UTF-8";

@mixin mq-sp($max: $sp-max-width) {
  @media print, screen and (max-width: #{$max}){
    @content;
  }
}

@mixin mq-tb($tb-min: $tb-min-width) {
  @media print, screen and (min-width: #{$tb-min}){
    @content;
  }
}


//@mixin mq-tub($tb-min: $tb-min-width, $tb-max:$tb-max-width) {
//  @media only screen and (min-width: #{$tb-min}) and (max-width: #{$tb-max}){
//    @content;
//  }
//}


@mixin mq-pc($min: $pc-min-width) {
  @media print, screen and (min-width: #{$min}){
    @content;
  }
}
