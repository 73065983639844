@charset "UTF-8";

@import "./base/variable";
@import "./base/mixin";

/* --main.html invitation-top reception-top 席札トップのみ使用　　*/
//メインヘッド（名入れ、挙式日）
@import "./component/common/mainhead";
//メインセレクト、手作りＯＲ印刷
@import "./component/common/mainselect";


//トップページ専用
@import "./pages/top";
