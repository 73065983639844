@charset "UTF-8";

.mainBody {
  &_item {
    margin-bottom: 40px;
    padding: 0 10px 0;
  }
  &_body {
    padding: 20px 10px 20px;
    border: 2px solid $color-pink;
    border-radius: 20px;
    overflow: hidden; //ボタンタグのfloat rightの解除
  }
  &_img {
    margin: 0 0 20px 0;
    width: 100%;
    height: auto;
  }
  &_foot {
  }
}

.conbination {
  padding: 90px 0 20px;
  margin-bottom: 20px;
  background: url("../img/combination-bg1.png") repeat-x;
  background-color: #fcfaf6;
  border-radius: 10px;
  @include mq-tb {
    margin-bottom: 40px;
  }
  &_head {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;
    @include mq-tb {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
  &_headText {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px;
    &-orange {
      background: linear-gradient(transparent 60%, #ffc46e 70%, #ffc46e 100%);
    }
    &-green {
      background: linear-gradient(transparent 60%, #beea53 70%, #beea53 100%);
    }
    @include mq-tb {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }
  &_body  {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.4;
    margin-bottom: 20px;
    & img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &_item {
    flex: 1 1 100%;
    max-width: 33%;
    align-self: flex-end;
    padding: 0 10px;
    @include mq-tb {
      padding: 0 30px;
    }
  }
  & h3 {
    font-weight: normal;
    @include mq-tb {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  & p {
    @include mq-tb {
      font-size: 20px;
      margin-bottom: 6px;
    }
  }
  &_down {
    padding: 0 0 0 30px;
    background: url("../img/combination-down.png") no-repeat;
    background-position: left 25% center;
    background-size: 18px;
    @include mq-tb {
      padding: 0 0 0 60px;
      background-position: left 38% center;
      background-size: 22px;
    }
  }
  &_line {
    width: 80%;
    margin-bottom: 10px;
  }
  & .conbination_sum {
    font-size: 18px;
    margin: 0 auto;
    padding: 10px 16px;
    width: 90%;
    background-color: #efefef;
    border-radius: 10px;
    @include mq-tb {
      font-size: 26px;
      max-width: 400px;
    }
  }
}

.subContainer {
  text-align: left;
  padding: 20px 10px 20px;
  border-top: 2px dashed #333333;
  @include mq-tb {
    padding: 30px 60px 30px;
  }
  &_head {
    text-align: center;
    font-size: 20px;
    margin: 0 0 15px 0;
    @include mq-tb {
      font-size: 40px;
      margin: 0 0 20px 0;
    }
  }
  &_body {
    font-size: 12px;
    line-height: 1.8;
    letter-spacing: 4px;
    @include mq-tb {
      font-size: 20px;
    }
  }
  &_mb {
    margin-bottom: 15px;
    @include mq-tb {
      margin-bottom: 25px;
    }
  }
}
