@charset "UTF-8";

.mainSelect {
  padding: 10px 5px 10px;
  margin-bottom: 20px;
  background: radial-gradient(#efebe8, #f2e4c7);
  background-size: cover;
  border-radius: 10px;
  @include mq-tb {
    padding: 20px 20px 20px;
    margin-bottom: 30px;
  }
  &_head {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 10px;
    @include mq-tb {
      font-size: 22px;
      margin-bottom: 30px;
    }
    & span {
      font-size: 16px;
      @include mq-tb {
        font-size: 30px;
      }
    }
  }
  &_body {
    display: flex;
  }
  &_bodyItem {
    width: 49%;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  &_bodyHead {
    font-size: 14px;
    margin-bottom: 8px;
    @include mq-tb {
      font-size: 30px;
    }
  }
  &_bodyBody {
    margin-bottom: 8px;
    width: 100%;
    height: auto;
  }
  & dl dl {
    margin-bottom: 10px;
    @include mq-tb {
      margin-bottom: 50px;
    }
  }
  & dl dl dd {
    padding-left: 30px;
    margin-bottom: 8px;
    text-align: left;
    @include mq-tb {
      padding-left: 50px;
    }
  }
  &_check1 dd {
    font-size: 12px;
    background: url("../img/check1.png") no-repeat;
    background-position: left 10px center;
    @include mq-tb {
      font-size: 22px;
      background-position: left 20px center;
    }
  }
  &_check2 dd {
    font-size: 12px;
    background: url("../img/check2.png") no-repeat;
    background-position: left 10px center;
    @include mq-tb {
      font-size: 22px;
      background-position: left 20px center;
    }
  }
  &_bodyFoot {
    font-size: 12px;
    margin-bottom: 14px;
    @include mq-tb {
      font-size: 24px;
    }
  }
}
